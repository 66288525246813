import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import { PayrollProviderName } from 'api/EmployersApi';
import { getApplicationData } from 'selectors/getApplicationData';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import { ReactComponent as FastBill } from 'images/fast-bill-w-clouds.svg';

import { getPayrollWidget } from './payrollProvider/PayrollProvider';

import styles from './AutomaticDeduction.module.scss';

const OUTPUT_VARIABLES_DECIMAL_PLACES = 2;

const AutomaticDeduction = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { application, isApplicationStatusUpdating } = useSelector(getApplicationData);
  const payrollProvider = application?.employment.employer1?.payrollProvider || PayrollProviderName.Argyle;

  const [open, setOpen] = useState(false);

  const handleOpenPayrollProvider = async () => {
    setOpen(true);
  };

  const PayrollWidget = payrollProvider && getPayrollWidget(payrollProvider);
  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <h1 className={styles.title}>Next, let’s update your direct deposit</h1>
        <p className={styles.description}>
          We are partners with {payrollProvider}, which provides an easy and secure way to{' '}
          {application?.fullDepositSwitch ? 'update your direct deposit.' : 'set up payroll deductions.'}
        </p>
        {!application?.fullDepositSwitch && application?.amountPerPaycheck && (
          <div className={styles.outputValueContainer}>
            <div className={styles.outputValueItem}>
              <p className={styles.subtitle}>Amount per paycheck</p>
              <p className={styles.outputValue}>
                ${application?.amountPerPaycheck.toFixed(OUTPUT_VARIABLES_DECIMAL_PLACES)}
              </p>
            </div>
          </div>
        )}
        <FastBill />
        {application?.fullDepositSwitch && (
          <p className={styles.notification}>
            On payday, we will automatically deduct your loan payment and send the rest to your external checking
            account.
          </p>
        )}
        <Button
          className={styles.button}
          onClick={handleOpenPayrollProvider}
          isLoading={isApplicationStatusUpdating || !application}
        >
          Get Started
        </Button>
        <>
          {PayrollWidget && application && (
            <PayrollWidget
              open={open}
              onClose={() => setOpen(false)}
              onSuccess={handleNext}
              application={application!}
              isDDS
            />
          )}
        </>
      </div>
    </>
  );
};

export default AutomaticDeduction;
