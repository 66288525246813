import { HardOfferData } from 'handlers/applicationData';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';

const useHardOfferData = () => {
  const { application } = useSelector(getApplicationData);

  const hardOffer = application?.hardOffer as HardOfferData;
  const payOff = hardOffer?.payOff || [];
  const keepIt = hardOffer?.keepIt || [];
  const additionalFundsTotal = hardOffer?.additionalFundsTotal || {};

  const tradelinesToPay = [...payOff, ...keepIt].filter((tradeline) => tradeline.weWillPay) || [];
  const tradelinesWeWillSendYouToPay =
    [...payOff, ...keepIt].filter((tradeline) => !tradeline.weWillPay && tradeline.selectedForConsolidation) || [];
  const amountWeWillPay = tradelinesToPay.reduce((sum, tradeline) => sum + tradeline.balanceToConsolidate, 0);
  const amountWeWillSendYouToPay = tradelinesWeWillSendYouToPay.reduce(
    (sum, tradeline) => sum + tradeline.balanceToConsolidate,
    0,
  );

  const additionalFunds = additionalFundsTotal?.amount ?? 0;

  return {
    additionalFunds,
    amountWeWillPay,
    amountWeWillSendYouToPay,
    tradelinesToPay,
    tradelinesWeWillSendYouToPay,
  };
};

export default useHardOfferData;
