import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DocuSignDocument } from 'api/DocuSignEnvelopeApi';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import LinkButton from 'components/LinkButton';
import LoanBreakdown from 'components/LoanBreakdown';
import PaymentMethod from 'components/ConfirmLoan/components/PaymentMethod';

import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ConfirmLoanResult } from 'enums/FlowNextResults';

import { RootState } from 'handlers';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { FlowComponentType } from 'routes/types';

import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';

import { createDocuSignEnvelope, getApplicationApr, updateApplicationStatus } from 'thunks';

import { ConversionType, trackConversion } from 'utils/analytics';

import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import APRBreakdown from './components/APRBreakdown';

import styles from './ConfirmLoan.module.scss';

const ConfirmLoan = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  const { application, isApplicationStatusUpdating, apr, isAprLoading, isLoading: isApplicationLoading } = useSelector(
    getApplicationData,
  );

  const { isLoading: isDocuSignEnvelopeLoading } = useSelector((state: RootState) => state.docuSignEnvelope);
  const { applied: cardApplied } = useSelector(getCardData);

  useEffect(() => {
    if (!apr && !isAprLoading && application) dispatch(getApplicationApr(application.id));
  }, [application]);

  const handleCreateDocuSignEnvelopment = async () => {
    const applicationId = application!.id;
    window.location.replace(
      await dispatchWithUnwrap(
        createDocuSignEnvelope({
          applicationId,
          windowLocationHref: window.location.href,
          document: DocuSignDocument.DraftLoanAgreement,
        }),
      ),
    );
  };

  const loanAmount = formatMonetaryAmount(application?.loanAmount, false);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <h1 className={styles.title}>Your Plannery Offer</h1>
        <p className={styles.loanAmountTitle}>Your Funds</p>
        <div className={styles.loanAmountContainer}>
          <p className={styles.loanAmount}>{loanAmount}</p>
        </div>

        <div className={styles.loanTermsContainer}>
          <p className={styles.loanTermsTitle}>Loan Terms:</p>

          <APRBreakdown />
          <PaymentMethod />

          {application?.loanTermInMonths && (
            <div className={styles.term}>
              <p className={styles.termTitle}>Loan Term</p>
              <p className={styles.termValue}>{application.loanTermInMonths} months</p>
            </div>
          )}
        </div>

        {application && (
          <LoanBreakdown application={application} container={styles.loanBreakdownContainer} title="Loan Breakdown:" />
        )}
        <LinkButton
          onClick={handleCreateDocuSignEnvelopment}
          className={styles.anchorButton}
          isLoading={isDocuSignEnvelopeLoading || !application}
        >
          Show me my draft Loan Agreement
        </LinkButton>
        <Button
          className={styles.button}
          onClick={async () => {
            trackConversion(ConversionType.ConfirmedLoan);
            await dispatchWithUnwrap(
              updateApplicationStatus({
                applicationId: application!.id,
                applicationStatus: ApplicationStatusName.Verification,
              }),
            );
            handleNext(ConfirmLoanResult.Continue);
          }}
          isLoading={isApplicationStatusUpdating || isApplicationLoading || !application}
        >
          Next
        </Button>
        {cardApplied && (
          <Button
            type={ButtonType.Inverted}
            className={styles.buttonMarginTop}
            onClick={() => {
              handleNext(ConfirmLoanResult.BackToYourFinances);
            }}
          >
            Not Now
          </Button>
        )}
      </div>
    </>
  );
};

export default ConfirmLoan;
