import React, { useCallback, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';

import {
  usePlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  PlaidLinkError,
  PlaidLinkOnEvent,
  PlaidLinkStableEvent,
  PlaidLinkOnEventMetadata,
} from 'react-plaid-link';
import { getLinkToken, setBankAccounts, getCheckingAccounts, finishPlaidIncomeVerification } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'handlers';

import Button from 'components/Button';

import { setPublicToken } from 'handlers/plaidPublicToken';
import { ReactComponent as Transfer } from 'images/transfer.svg';
import { ReactComponent as Profile } from 'images/profile.svg';
import { ReactComponent as Secure } from 'images/lock.svg';
import { ReactComponent as PlaidLogo } from 'images/plaid.svg';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import PartnerBanner from 'components/Common/PartnerBanner';
import BankAccount from 'components/Verification/Steps/CheckingAccount/BankAccount';
import SelectAccount from 'components/Verification/Steps/CheckingAccount/SelectAccount';
import { VerificationStep, useVerificationSteps } from 'components/Verification/verificationSteps';
import { ButtonType } from 'components/Button/Button';
import useCurrentFlow from 'hooks/useCurrentFlow';
import FormNavigation from 'components/FormNavigation';
import { StepsResult } from 'enums/FlowNextResults';

import styles from './Plaid.module.scss';

const descriptionItems = [
  {
    description: 'Retrieves your account and routing number for depositing your funds and making payments.',
    missedPaymentDescription: 'Make your loan payment with one click. No typos or long numbers to enter.',
    icon: Transfer,
  },
  {
    description: 'Verifies your identity and income.',
    missedPaymentDescription: 'Ensure there are funds in the account to avoid overdraft fees.',
    icon: Profile,
  },
  {
    description: 'Uses bank level security protocols.',
    missedPaymentDescription: 'Cancel authorization anytime.',
    icon: Secure,
  },
];

enum PlaidEvent {
  BANK_INCOME_INSIGHTS_COMPLETED = 'BANK_INCOME_INSIGHTS_COMPLETED',
  HANDOFF = 'HANDOFF',
}

const PlaidLink = ({ handleNext }: StepComponent): JSX.Element => {
  const [token, setToken] = useState<string | null>(null);
  const [exitedPlaid, setExitedPlaid] = useState<boolean>(false);
  const [plaidCompleted, setPlaidCompleted] = useState<boolean>(false);
  // @ts-ignore-next-line
  const [incomeCompleted, setIncomeCompleted, incomeCompletedRef] = useStateRef<boolean>(false);

  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { isMissedPaymentFlow } = useCurrentFlow();
  const { stepsProgress: verificationStepsStatus, isLastStep } = useVerificationSteps();
  const { application } = useSelector((state: RootState) => state.applicationData!);

  const applicationId = application?.id;

  useEffect(() => {
    let mounted = true;

    if (application?.plaidTokenLastUpdated) {
      const lastUpdated = new Date(application.plaidTokenLastUpdated!);
      const now = new Date();
      const diff = Math.abs(now.getTime() - lastUpdated.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays < 30) {
        return;
      }
    }

    const createLinkToken = async () => {
      const response = await dispatchWithUnwrap(getLinkToken(applicationId!));
      const { LINK_TOKEN: linkToken } = response;
      mounted && setToken(linkToken);
    };

    createLinkToken();

    return () => {
      mounted = false;
    };
  }, [application]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (plaidPublicToken: string) => {
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    await dispatchWithUnwrap(setBankAccounts({ publicToken: plaidPublicToken, applicationId: applicationId! }));
    if (incomeCompleted || incomeCompletedRef.current) {
      dispatch(finishPlaidIncomeVerification(applicationId!));
    }
    dispatch(getCheckingAccounts({ applicationId: applicationId!, publicToken: plaidPublicToken }));
    dispatch(setPublicToken({ publicToken: plaidPublicToken }));
    analytics.track('Checking Account Connected through Plaid');
  }, []);

  const handleEvent = useCallback<PlaidLinkOnEvent>(
    (evtName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
      analytics.track(`Plaid Event: ${evtName}`, {
        metadata,
      });

      switch (evtName) {
        case PlaidEvent.BANK_INCOME_INSIGHTS_COMPLETED:
          setIncomeCompleted(true);
          break;
        case PlaidEvent.HANDOFF:
          setPlaidCompleted(true);
          break;
        default:
          break;
      }
    },
    [],
  );

  const handleExit = (error: PlaidLinkError | null) => {
    analytics.track('Plaid Link Closed', {
      ...(error && { error }),
    });
    setExitedPlaid(true);
  };

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit: handleExit,
    onEvent: handleEvent,
  };

  const { open, ready } = usePlaidLink(config);

  if (exitedPlaid) {
    return <BankAccount handleNext={handleNext} />;
  }

  if (plaidCompleted) {
    return <SelectAccount handleNext={handleNext} />;
  }

  return (
    <>
      {isMissedPaymentFlow && <FormNavigation title="Bank Account" step={3} stepCount={5} />}
      <FormContainer
        title="Connect Your Checking Account"
        subtitle="This is the account where you deposit your paycheck and pay bills."
      >
        <div className={styles.container}>
          <PartnerBanner name="Plaid" logo={<PlaidLogo />} />
          <div className={styles.descriptionContainer}>
            <div className={styles.descriptionItems}>
              {descriptionItems.map((item, index) => (
                <div className={styles.item} key={index}>
                  <item.icon className={styles.icon} />
                  <div className={styles.itemText}>
                    {isMissedPaymentFlow ? item.missedPaymentDescription : item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {verificationStepsStatus[VerificationStep.CheckingAccount] && !isMissedPaymentFlow ? (
            <div className={styles.verified}>
              <CheckIcon className={styles.checkIcon} />
              <p className={styles.verifiedLabel}>Account connected</p>
            </div>
          ) : (
            <>
              <Button className={styles.button} onClick={() => open()} isLoading={!ready || !application}>
                {isMissedPaymentFlow ? 'Link My Account' : 'Next'}
              </Button>

              {!isMissedPaymentFlow && !isLastStep && (
                <Button
                  className={styles.secondaryButton}
                  onClick={() => handleNext(StepsResult.DoThisLater)}
                  type={ButtonType.Secondary}
                >
                  Do this later
                </Button>
              )}
            </>
          )}
        </div>
      </FormContainer>
    </>
  );
};

export default PlaidLink;
