import { createAsyncThunk } from '@reduxjs/toolkit';
import { ArgyleApi } from 'api/ArgyleApi';

export const createSetArgyleVerificationPendingThunk = (api: ArgyleApi) =>
  createAsyncThunk('argyle/setVerificationPending', async (applicationId: string, thunkApi) => {
    try {
      await api.setVerificationPending(applicationId);
    } catch (error: unknown) {
      return thunkApi.rejectWithValue((error as any).response?.data);
    }
  });
