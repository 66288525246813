import React from 'react';

import { ReactComponent as RoadSign } from 'images/road-sign.svg';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import { ComeBackWhenDueResult } from 'enums/StudentLoanForgivenessFlowResults';

import styles from './NoSavings.module.scss';

const NoSavings = ({ handleNext, navigationInfo }: FlowComponentType) => (
  <div>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <RoadSign />
      </div>
      <div className={styles.title}>You're all set! Come back if something changes. </div>
      <div className={styles.description}>
        It looks like you’re already enrolled in the right programs. If something changes, come back and update us.
      </div>
      <div className={styles.buttonContainer}>
        <Button type={ButtonType.Primary} onClick={() => handleNext(ComeBackWhenDueResult.Done)}>
          Continue
        </Button>
        <Button type={ButtonType.Secondary} onClick={() => handleNext(ComeBackWhenDueResult.TalkToAnAdvisor)}>
          Talk to an Advisor
        </Button>
      </div>
    </div>
  </div>
);

export default NoSavings;
