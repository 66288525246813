import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import LoanBreakdown from 'components/LoanBreakdown';
import Expander from 'components/Expander';

import { RoutePath } from 'enums/Routes';

import { ReactComponent as CheckIcon } from 'images/check-icon-rounded.svg';

import { getApplicationData as getApplicationDataSelector } from 'selectors/getApplicationData';

import { setApplicationApproved } from 'handlers/applicationData';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useHardOfferData from 'hooks/useHardOfferData';
import { useNavigate } from 'hooks/useNavigate';

import { getApplicationData } from 'thunks';

import { addWorkDays } from 'utils/dateUtils';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ConversionType, trackConversion } from 'utils/analytics';

import styles from './FundsSent.module.scss';

const FundsSent = () => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();
  const { application } = useSelector(getApplicationDataSelector);
  const { amountWeWillSendYouToPay, amountWeWillPay } = useHardOfferData();
  const fundsArrivalDate = application?.dateOfLoan
    ? addWorkDays(new Date(application.dateOfLoan!), 3)
    : addWorkDays(new Date(), 3);

  useEffect(() => {
    dispatch(setApplicationApproved());

    const refreshApplication = async () => {
      if (application) {
        await dispatchWithUnwrap(getApplicationData(application?.id));
      }
    };
    refreshApplication();

    trackConversion(ConversionType.LoanFundsSent);
  }, []);

  const handleNext = () => {
    navigate(RoutePath.Feedback);
  };

  const amountWeWillSendText =
    amountWeWillPay > 0 ? formatMonetaryAmount(amountWeWillSendYouToPay, false) : 'your money';
  const amountWeWillPayText =
    amountWeWillSendYouToPay > 0
      ? `the remaining ${formatMonetaryAmount(amountWeWillPay, false)}`
      : `${formatMonetaryAmount(amountWeWillPay, false)} of your current debt`;

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <CheckIcon />
      </div>
      <>
        <div className={styles.title}>
          {amountWeWillSendYouToPay > 0 && (
            <>
              We're sending {amountWeWillSendText} to your {application?.accountMask} account!
            </>
          )}
          <br />
          {amountWeWillPay > 0 && <>We'll pay off {amountWeWillPayText}</>}
        </div>
        <div className={styles.description}>
          Funds will arrive in your account by{' '}
          {fundsArrivalDate?.toLocaleString('en', { month: 'long', day: 'numeric' })}. You will receive an email when
          your debts have been paid by us.
        </div>
        {application && (
          <Expander
            label="Loan Breakdown"
            content={<LoanBreakdown application={application} subtitle="Loan Amount" showFees={false} isFinal />}
            labelClassname={styles.loanBreakdownTitle}
            container={styles.expanderContainer}
            openClassname={styles.expanderOpen}
            useEllipse
          />
        )}
        <Button onClick={handleNext}>Next</Button>
      </>
    </div>
  );
};

export default FundsSent;
