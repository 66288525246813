import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button, { ButtonType } from 'components/Button/Button';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg';
import { ReactComponent as EmailIcon } from 'images/email-icon.svg';
import { getApplicationData } from 'selectors/getApplicationData';
import { getLoanOfferData } from 'selectors/getLoanOfferData';
import OutOfState from 'components/OutOfState';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { ConversionType, trackConversion } from 'utils/analytics';
import { getCardData } from 'selectors/getCardData';
import { OfferStatusResult } from 'enums/FlowNextResults';
import { FlowComponentType } from 'routes/types';
import ErrorConsolidating from 'components/DebtConsolidation/ErrorConsolidating/ErrorConsolidating';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';

import styles from './OfferStatus.module.scss';

const renderContent = (status: ApplicationStatusName | ApplicationStatusName) => {
  switch (status) {
    case ApplicationStatusName.Rejected: {
      return {
        title: 'That’s a bummer',
        subtitle: `It doesn't look like we can approve you for a loan.`, // TODO: Add "healthcare employee" in front of "loan"
      };
    }

    case ApplicationStatusName.AlreadyApplied: {
      return {
        title: 'That’s a bummer',
        subtitle: 'Looks like you have already applied.',
      };
    }

    case ApplicationStatusName.ManualVerify: {
      return {
        title: 'Thanks, we’re confirming your information!',
        subtitle: 'We’re verifying your information, and will contact you once your account is ready!',
      };
    }

    case ApplicationStatusName.FinalReview: {
      return {
        title: 'Final Review',
        subtitle: (
          <>
            <p>
              As a final step, we are reviewing that everything has been completed successfully. We will contact you
              when it’s time to sign the loan agreement.
            </p>
            <br />
            <p>After signing, you will receive your funds within 3 business days.</p>
          </>
        ),
      };
    }

    default: {
      return {
        title: 'We are working on your offer',
        subtitle: 'As soon as we are ready we will contact you.',
      };
    }
  }
};

const isRejected = (status: ApplicationStatusName | ApplicationStatusName) => status === ApplicationStatusName.Rejected;

const isOutOfState = (status: ApplicationStatusName | ApplicationStatusName) =>
  status === ApplicationStatusName.OutOfState;

const OfferStatus = ({ handleNext, flags, navigationInfo }: FlowComponentType): JSX.Element => {
  const appData = useSelector(getApplicationData).application!;
  const offerResult = useSelector(getLoanOfferData).data!;
  const { applied: cardApplied } = useSelector(getCardData);

  const { isFinancialCheckupFlow, isDebtConsolidationFlow } = useCurrentFlow();

  const applicationStatus = appData?.status || offerResult?.application_status;

  const handleBackToFinances = () => {
    handleNext(OfferStatusResult.BackToYourFinances);
  };

  const handleBackToHomepage = () => {
    window.open('https://www.planneryapp.com/');
  };

  useEffect(() => {
    if (applicationStatus) {
      analytics.track(applicationStatus);
      if (applicationStatus === ApplicationStatusName.ManualVerify) {
        trackConversion(ConversionType.LoanManualVerify);
      }
    }
  }, []);

  if (isOutOfState(applicationStatus)) {
    return <OutOfState />;
  }
  if (isRejected(applicationStatus)) {
    return <ErrorConsolidating handleNext={handleNext} flags={flags} navigationInfo={navigationInfo} />;
  }

  const content = renderContent(applicationStatus);

  const allowBackToFinances = isFinancialCheckupFlow && applicationStatus !== ApplicationStatusName.AlreadyApplied;

  const showWhatsNext = isDebtConsolidationFlow;

  return (
    <>
      <div className={styles.container}>
        {applicationStatus === ApplicationStatusName.ManualVerify && (
          <div className={styles.iconContainer}>
            <CheckIcon className={styles.checkIcon} />
          </div>
        )}
        <h1 className={styles.title}>{content.title}</h1>
        <div className={styles.subtitle}>{content.subtitle}</div>
        {allowBackToFinances && !cardApplied && (
          <div className={styles.buttonContainer}>
            <Button className={styles.primaryButton} onClick={handleBackToFinances}>
              Back to Your Finances
            </Button>
          </div>
        )}
        {cardApplied && (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.primaryButton}
              onClick={() => {
                handleNext(OfferStatusResult.BackToCard);
              }}
            >
              Continue
            </Button>
          </div>
        )}
        <p className={styles.questionsTitle}>Questions?</p>
        <div className={styles.contactsContainer}>
          <a href="sms:(404) 205-8882" className={styles.contactItemContainer}>
            <ChatIcon />
            <p className={styles.contactsTitle}>Text or call us</p>
            <p className={styles.contactsSubtitle}>(404) 205-8882</p>
          </a>
          <a href="mailto:outofstate@planneryapp.com" className={styles.contactItemContainer}>
            <EmailIcon />
            <p className={styles.contactsTitle}>Email us</p>
            <p className={styles.contactsSubtitle}>Typically respond within 24-48 hours</p>
          </a>
        </div>
        {showWhatsNext && (
          <>
            <p className={styles.whatsNextTitle}>What's Next</p>
            <div className={styles.subtitle}>
              In the meantime, see how else Plannery can help, and keep track of your application status.
            </div>
            <div className={styles.buttonContainer}>
              <Button className={styles.primaryButton} onClick={handleBackToFinances}>
                See Your Finances
              </Button>
            </div>
          </>
        )}
        {!allowBackToFinances && !showWhatsNext && (
          <Button type={ButtonType.Secondary} onClick={handleBackToHomepage} className={styles.secondaryButton}>
            Back to Plannery homepage
          </Button>
        )}
      </div>
    </>
  );
};

export default OfferStatus;
