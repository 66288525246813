import React from 'react';
import useDropdown from 'hooks/useDropdown';
import clsx from 'clsx';

import { ReactComponent as PlayIcon } from 'images/play-button.svg';
import { Tradeline } from 'handlers/applicationData';

import styles from './OtherDebts.module.scss';

interface OtherDebtsProps {
  dropdown: ReturnType<typeof useDropdown>;
  cantConsolidate: Tradeline[];
}

const OtherDebts = ({ dropdown, cantConsolidate }: OtherDebtsProps) => {
  const { isOpen, handleToggleDropdown, height, contentRef } = dropdown;

  return (
    <div className={styles.container}>
      <div onClick={handleToggleDropdown} className={styles.header}>
        <div className={styles.subtitle}>
          Plannery is not currently able to consolidate car, home, or student debt, joint or authorized accounts, or
          delinquent accounts with this loan.
        </div>
        <PlayIcon
          className={clsx(styles.icon, {
            [styles.open]: isOpen,
          })}
        />
      </div>

      <div
        ref={contentRef}
        style={{
          height: `${height}px`,
        }}
        className={clsx(styles.content, {
          [styles.toggleDropdown]: isOpen,
        })}
      >
        <div className={styles.list}>
          {cantConsolidate.map((t, index) => (
            <div key={`${t.firm?.trim()}-${index}`} className={styles.row}>
              {t.firm} {t.accountMask}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherDebts;
