import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'hooks/useNavigate';
import { RoutePath } from 'enums/Routes';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { getApplicationData } from 'selectors/getApplicationData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { formatNumber } from 'utils/formatNumber';
import { ReactComponent as SelectArrow } from 'images/select-arrow.svg';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { getPaymentMethodLabel } from 'components/ConfirmLoan/components/PaymentMethod';

import { HardOfferData } from 'handlers/applicationData';
import { getApplicationApr } from 'thunks';

import styles from './YourSavingsDropdown.module.scss';

interface YourSavingDropdownProps {}

const YourSavingDropdown: FC<YourSavingDropdownProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const { application, apr, isAprLoading } = useSelector(getApplicationData);
  const hardOffer = (application?.hardOffer ?? {}) as Partial<HardOfferData>;
  const {
    payOffTotal: { amount: payOffAmount },
    offerSummary: { moneySaved, monthlyPaymentSaved },
  } = hardOffer as HardOfferData;

  const paymentPerPaycheck = formatMonetaryAmount(application?.amountPerPaycheck, true);
  const dropdownHeaderRef = useDetectClickOutside({ onTriggered: () => setOpenDropdown(false) });
  const loanAmount = formatMonetaryAmount(application?.loanAmount, false);

  useEffect(() => {
    if (!apr && !isAprLoading && application) dispatch(getApplicationApr(application.id));
  }, [application]);

  const getAmountAndTitle = () => {
    const hasDebtConsolidation = payOffAmount > 0;

    if (hasDebtConsolidation) {
      if (moneySaved && moneySaved >= 100) {
        return { title: 'Your savings', titleAmount: formatMonetaryAmount(moneySaved) };
      }

      if (monthlyPaymentSaved && monthlyPaymentSaved >= 5) {
        const amount = formatMonetaryAmount(monthlyPaymentSaved);
        return { title: 'Your savings', titleAmount: `${amount}/mo` };
      }
    }

    return { title: 'Your funds', titleAmount: loanAmount };
  };

  const { title, titleAmount } = useMemo(getAmountAndTitle, [
    payOffAmount,
    moneySaved,
    monthlyPaymentSaved,
    loanAmount,
  ]);

  return (
    <div className={styles.dropdownContainer}>
      <div
        className={clsx(styles.dropdownHeader, openDropdown && styles.dropdownHeaderActive)}
        onClick={() => setOpenDropdown(!openDropdown)}
        ref={dropdownHeaderRef}
      >
        <p className={styles.dropdownOptionText}>{title}:</p>
        <p className={styles.dropdownHighlightSubtitle}>{titleAmount}</p>

        <SelectArrow className={clsx(styles.selectArrow, openDropdown && styles.selectArrowActive)} />
      </div>

      <div className={clsx(styles.dropdownContent, openDropdown && styles.dropdownContentActive)}>
        <div className={clsx(styles.dropdownOption, styles.dropdownOptionSpace)}>
          <p>Your Funds</p>
          <p className={styles.dropdownOptionHighlightText}>{loanAmount}</p>
        </div>
        {apr && (
          <div className={styles.dropdownOption}>
            <p>APR</p>
            <p className={styles.dropdownOptionText}>{formatNumber(apr, true)}%</p>
          </div>
        )}
        {application?.monthlyLoanPayment && (
          <div className={styles.dropdownOption}>
            <p>Payment per {getPaymentMethodLabel(application?.paymentMethod)}</p>
            <p className={styles.dropdownOptionText}>{paymentPerPaycheck}</p>
          </div>
        )}
        {application?.loanTermInMonths && (
          <div className={styles.dropdownOption}>
            <p>Loan Term</p>
            <p className={styles.dropdownOptionText}>{application?.loanTermInMonths} Months</p>
          </div>
        )}
        <Button type={ButtonType.Primary} onClick={() => navigate(RoutePath.PayOffPlan)}>
          Customize Offer
        </Button>
      </div>
    </div>
  );
};

export default YourSavingDropdown;
