import React from 'react';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';

import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import ComparisonTable from 'components/Common/Tables/ComparisonTable';
import { getDebtFreeLabels } from 'components/StudentLoanForgiveness/commonCalculations';

import styles from './SavingsTable.module.scss';

const noPath = {
  monthsToDebtFree: NaN,
  monthlyPayment: 0,
  totalPayment: 0,
  payments: [],
};

const SavingsTable = () => {
  const { currentPath: currentPathData, recommendedPath: recommendedPathData } = useSelector(getStudentLoanData);

  const savings =
    currentPathData?.totalPayment !== undefined && recommendedPathData?.totalPayment !== undefined
      ? currentPathData?.totalPayment - recommendedPathData?.totalPayment
      : null;

  if (!savings) {
    return <></>;
  }

  const currentPath = currentPathData ?? noPath;
  const {
    monthsToDebtFree: currentPathMonths,
    monthlyPayment: currentPathMonthlyPayment,
    totalPayment: currentPathTotalPayment,
  } = currentPath;

  const recommendedPath = recommendedPathData ?? noPath;
  const {
    monthsToDebtFree: recommendedPathMonths,
    monthlyPayment: recommendedPathMonthlyPayment,
    totalPayment: recommendedPathTotalPayment,
  } = recommendedPath;

  const showMonths =
    currentPathMonths !== undefined &&
    currentPathMonths > 0 &&
    recommendedPathMonths !== undefined &&
    recommendedPathMonths >= 0 &&
    recommendedPathMonths < currentPathMonths;
  const showMonthlyPayment = recommendedPathMonthlyPayment < currentPathMonthlyPayment;
  const showTotalPayment = recommendedPathTotalPayment < currentPathTotalPayment;

  const { currentPathDebtFreeLabel, recommendedPathDebtFreeLabel } = getDebtFreeLabels(
    currentPathMonths,
    recommendedPathMonths,
  );

  return (
    <div className={styles.payoffContent}>
      <div className={styles.tableAndAssumptions}>
        <ComparisonTable
          comparisonLabel="vs"
          leftColumn={{
            title: 'Current Path',
            rows: [
              ...(showMonths ? [{ amount: currentPathDebtFreeLabel, label: 'debt free' }] : []),
              ...(showMonthlyPayment
                ? [{ amount: formatMonetaryAmount(currentPathMonthlyPayment), label: 'monthly payment' }]
                : []),
              ...(showTotalPayment
                ? [{ amount: formatMonetaryAmount(currentPathTotalPayment), label: 'total payment' }]
                : []),
            ],
          }}
          rightColumn={{
            title: 'With Plannery',
            rows: [
              ...(showMonths ? [{ amount: recommendedPathDebtFreeLabel, label: 'debt free' }] : []),
              ...(showMonthlyPayment
                ? [{ amount: formatMonetaryAmount(recommendedPathMonthlyPayment), label: 'monthly payment' }]
                : []),
              ...(showTotalPayment
                ? [{ amount: formatMonetaryAmount(recommendedPathTotalPayment), label: 'total payment' }]
                : []),
            ],
          }}
        />
      </div>
    </div>
  );
};

export default SavingsTable;
