import React, { FC, useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import useDropdown from 'hooks/useDropdown';
import { ReactComponent as ChevronIcon } from 'images/chevron-simple.svg';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { HardOfferData, Tradeline } from 'handlers/applicationData';
import Loader from 'components/Loader';
import LinkButton from 'components/LinkButton';
import { setTradelinesForConsolidationAndCalculateBalance } from 'utils/tradelineUtils';

import {
  getApplicationApr,
  updateSelectedTradelines,
  setRecommendationStrategy,
  updateLoanOffer,
  getApplicationData,
} from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { failureNotification } from 'notifications';

import { RootState } from 'handlers';
import { useSelector } from 'react-redux';

import { RecommendationStrategy, SelectedTradeline } from 'api/ApplicationDataApi';

import ConsolidationTable from './ConsolidationTable';
import OtherDebts from './OtherDebts';

import styles from './DebtConsolidationDropdown.module.scss';

interface DebtConsolidationProps {
  data: HardOfferData;
  maxLoanAmount: number;
}

const DebtConsolidationDropdown: FC<DebtConsolidationProps> = ({ data, maxLoanAmount }): JSX.Element => {
  const subDropdown = useDropdown(); // Other loans
  const { isOpen, handleToggleDropdown, contentRef } = useDropdown();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { isLoading: isLoadingApplicationData, application } = useSelector((state: RootState) => state.applicationData);
  const [isSettingRecommendationStrategy, setIsSettingRecommendationStrategy] = useState(false);
  const isLoading = isLoadingApplicationData || isSettingRecommendationStrategy;

  const [selectedTradelines, setSelectedTradelines] = useState<Record<string, SelectedTradeline>>({});
  const containerRef = useRef<HTMLDivElement>(null);

  const [editData, setEditData] = useState<HardOfferData>(data);
  const { payOff, planneryLoan, keepIt, cantConsolidate } = editData;
  const tradelines = [...payOff, ...keepIt];
  const tradelinesConsolidated = [...payOff, ...keepIt].filter((t) => t.selectedForConsolidation);
  const currentLoanAmount = tradelinesConsolidated.reduce((acc, t) => acc + t.balanceToConsolidate, 0);
  const currentNumberOfAccounts = tradelinesConsolidated.length;

  const validLoanAmount = currentLoanAmount <= maxLoanAmount;

  const isEditing =
    Object.keys(selectedTradelines).length > 0 &&
    payOff.every((tradeline) => tradeline.id) &&
    keepIt.every((tradeline) => tradeline.id);

  const recommendationStrategies = [
    { name: 'Lowest Monthly Payment', value: RecommendationStrategy.OptimizeForMonthlyPayment },
    {
      name: 'Lowest Interest',
      value: RecommendationStrategy.OptimizeForTotalSavings,
    },
    {
      name: 'Balanced',
      value: RecommendationStrategy.OptimizeForBoth,
    },
  ];
  const recommendationStrategy = editData.manuallyUpdatedSelections ? null : application?.recommendationStrategy;

  useEffect(() => {
    // Update data when changes are made
    setEditData(data);
  }, [data]);

  useEffect(() => {
    if (currentLoanAmount > maxLoanAmount) {
      failureNotification(
        `You have selected the max amount of ${formatMonetaryAmount(maxLoanAmount)}. Deselect an account first.`,
      );
    }
  }, [validLoanAmount]);

  const handleToggle = () => {
    // Close the inner dropdown if the main one is closed to avoid wrong height
    if (isOpen && subDropdown.isOpen) {
      subDropdown.handleToggleDropdown();
    }
    handleToggleDropdown();
    if (!isOpen) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSelectedTradelinesOnClick = useCallback(async () => {
    await dispatchWithUnwrap(
      updateSelectedTradelines({
        applicationId: application!.id,
        tradelinesToUpdate: Object.values(selectedTradelines),
      }),
    );
    await dispatchWithUnwrap(getApplicationApr(application!.id));
    setSelectedTradelines({});
  }, [selectedTradelines, planneryLoan, application]);

  const handleCancelEdit = () => {
    setSelectedTradelines({});
    setEditData(data);
  };

  const handleSetSelectedTradelines = (tradelinesWithModifications: Record<string, SelectedTradeline>) => {
    setSelectedTradelines(tradelinesWithModifications);
    const newEditData = { ...data };
    const originalTradelinesConsolidated = [...newEditData.payOff, ...newEditData.keepIt].filter(
      (t) => t.selectedForConsolidation,
    );

    const remainingTradelines = originalTradelinesConsolidated.filter((t) => !tradelinesWithModifications[t.id]);
    const removeTradelines = originalTradelinesConsolidated
      .filter((t) => tradelinesWithModifications[t.id])
      .map((t) => ({
        ...t,
        selectedForConsolidation: false,
        balanceToConsolidate: 0,
      }));

    const newTradelines: Tradeline[] = Object.keys(tradelinesWithModifications)
      .filter((key) => !originalTradelinesConsolidated.find((t) => t.id === key))
      .map((key) => tradelines.find((t) => t.id === key) as Tradeline);

    const rebalancedTradelines = setTradelinesForConsolidationAndCalculateBalance(
      [...remainingTradelines, ...newTradelines],
      maxLoanAmount,
    );

    newEditData.payOff = newEditData.payOff.map(
      (t1) =>
        rebalancedTradelines.find((t2) => t1.id === t2.id) ?? removeTradelines.find((t2) => t1.id === t2.id) ?? t1,
    );
    newEditData.keepIt = newEditData.keepIt.map(
      (t1) =>
        rebalancedTradelines.find((t2) => t1.id === t2.id) ?? removeTradelines.find((t2) => t1.id === t2.id) ?? t1,
    );

    setEditData(newEditData);
  };

  const handleSetRecommendationStrategy = async (strategy: RecommendationStrategy) => {
    setIsSettingRecommendationStrategy(true);
    await dispatchWithUnwrap(
      setRecommendationStrategy({
        applicationId: application!.id,
        strategy,
      }),
    );
    await dispatchWithUnwrap(
      updateLoanOffer({
        applicationId: application!.id,
      }),
    );
    await dispatchWithUnwrap(getApplicationData(application!.id));
    await dispatchWithUnwrap(getApplicationApr(application!.id));
    setIsSettingRecommendationStrategy(false);
  };

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, {
        [styles.mobileOpen]: isOpen,
      })}
    >
      <div className={styles.header} onClick={handleToggle}>
        <div className={styles.headerTitle}>
          <div className={styles.consolidationAmount}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader color="#9d86f9" size={25} />
              </div>
            ) : (
              <>
                <span className={styles.accounts}>{formatMonetaryAmount(currentLoanAmount as number)}</span> /{' '}
                {currentNumberOfAccounts} account
                {currentNumberOfAccounts !== 1 && 's'}
              </>
            )}
          </div>
        </div>
        <div
          className={clsx(styles.dropdownButton, {
            [styles.isToggled]: isOpen,
          })}
        >
          <ChevronIcon />
        </div>
      </div>

      <div
        ref={contentRef}
        className={clsx(styles.content, {
          [styles.dropdownOpen]: isOpen,
        })}
      >
        {isEditing && (
          <div className={styles.actions}>
            <LinkButton isLoading={isLoading} onClick={handleCancelEdit}>
              Cancel
            </LinkButton>
            <LinkButton onClick={handleSelectedTradelinesOnClick} isLoading={isLoading} disabled={!validLoanAmount}>
              Submit changes
            </LinkButton>
          </div>
        )}

        {!isEditing && (
          <div className={styles.toggleButtonGroup}>
            {recommendationStrategies.map((strategy) => (
              <div
                key={strategy.value}
                className={clsx(styles.toggleButton, {
                  [styles.selected]: strategy.value === recommendationStrategy,
                  [styles.disabled]: isLoading,
                })}
                onClick={() => handleSetRecommendationStrategy(strategy.value)}
              >
                {strategy.name}
              </div>
            ))}
          </div>
        )}

        <ConsolidationTable
          isLoading={isLoading}
          tradeLines={tradelines}
          selectedTradelines={selectedTradelines}
          setSelectedTradelines={handleSetSelectedTradelines}
        />

        <div className={styles.hint}>
          <div className={styles.hintText}>* Estimate is based on your credit report.</div>
          <div className={styles.hintText}>The market average is used when your exact data is unavailable.</div>
        </div>

        <div className={styles.separator} />

        <OtherDebts dropdown={subDropdown} cantConsolidate={cantConsolidate} />
      </div>
    </div>
  );
};
export default DebtConsolidationDropdown;
