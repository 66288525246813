import { createAsyncThunk } from '@reduxjs/toolkit';
import { ArgyleApi, SaveArgyleAccountData } from 'api/ArgyleApi';

import { ErrorResponse } from './ErrorResponse';

export const createSaveArgyleAccountThunk = (api: ArgyleApi) =>
  createAsyncThunk<void, SaveArgyleAccountData>('argyle/saveAccount', async (data: SaveArgyleAccountData, thunkApi) => {
    try {
      return await api.saveArgyleAccount(data);
    } catch (error: unknown) {
      return thunkApi.rejectWithValue((error as ErrorResponse).response.data);
    }
  });

export default createSaveArgyleAccountThunk;
