import { PARTNER_DATA, PartnerType } from 'enums/PartnerName';
import { DiscountType, HardOfferData } from 'handlers/applicationData';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';

interface ApplicableDiscount {
  type: DiscountType;
  isTenureDiscount: boolean;
  aprDifference: number;
  partnerType?: PartnerType;
  tenureYears?: number;
  discountName?: string;
}

const useDiscounts = () => {
  const { application } = useSelector(getApplicationData);
  const hardOffer = (application?.hardOffer ?? {}) as Partial<HardOfferData>;
  const { discountSummary } = (hardOffer ?? {}) as HardOfferData;
  const { items, baseOriginationFee } = discountSummary ?? {};

  const applicableDiscounts: ApplicableDiscount[] = items
    ? items.map(
        (item): ApplicableDiscount => {
          const partnerId = ('partnerId' in item.reason && item?.reason.partnerId) || '';
          const employerName = ('employerName' in item.reason && item?.reason.employerName) || '';
          const tenureYears = ('tenureYears' in item.reason && item.reason?.tenureYears) || undefined;
          const isTenureDiscount = item.type === DiscountType.Tenure;

          return {
            tenureYears,
            isTenureDiscount,
            type: item.type,
            aprDifference: item.aprDifference,
            partnerType: partnerId ? PARTNER_DATA[partnerId]?.type : undefined,
            discountName: partnerId ? PARTNER_DATA[partnerId].shortName : employerName,
          };
        },
      )
    : [];

  const totalPartnerFeeDiscount: number = (() => {
    if (!items) return 0;
    const partnerItem = items.find((item) => item.type === DiscountType.Partner);
    return partnerItem ? partnerItem.originationFeeDiscount : 0;
  })();

  const getHighlightedDiscountData = () => {
    const badgeDiscount = applicableDiscounts[0];

    if (!badgeDiscount) return undefined;

    const { discountName, tenureYears, type, partnerType } = badgeDiscount;
    const isTenureDiscount = type === DiscountType.Tenure;

    const highlightedText = isTenureDiscount
      ? 'loyalty discount'
      : `${discountName} ${partnerType === PartnerType.Association ? 'member' : ''} discount.`;
    const badgeCustomDescription = isTenureDiscount
      ? `for working at ${discountName} for over ${tenureYears} ${tenureYears === 1 ? 'year' : 'years'}.`
      : '';

    return {
      ...badgeDiscount,
      badgeCustomDescription,
      highlightedText,
    };
  };

  return { items, baseOriginationFee, applicableDiscounts, totalPartnerFeeDiscount, getHighlightedDiscountData };
};

export default useDiscounts;
