import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { PaymentMethod as PaymentMethodEnum } from 'enums/PaymentMethod';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationData } from 'selectors/getApplicationData';
import { getApplicationApr, updatePaymentMethod } from 'thunks';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import Loader from 'components/Loader';
import { ReactComponent as SelectArrow } from 'images/select-arrow.svg';

import styles from './PaymentMethod.module.scss';

const PaymentMethod: FC = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application, isLoading: isApplicationLoading } = useSelector(getApplicationData);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(application?.paymentMethod);

  const handleUpdatePaymentMethod = async () => {
    setShowPaymentOptions(false);
    const newPaymentMethod =
      application?.paymentMethod === PaymentMethodEnum.Payroll ? PaymentMethodEnum.ACH : PaymentMethodEnum.Payroll;

    setPaymentMethod(newPaymentMethod);
    await dispatchWithUnwrap(updatePaymentMethod({ applicationId: application!.id, paymentMethod: newPaymentMethod }));
    await dispatchWithUnwrap(getApplicationApr(application!.id));
  };

  const paymentPerPaycheck = formatMonetaryAmount(application?.amountPerPaycheck, true);

  return (
    <div className={clsx(styles.paymentMethodContainer)}>
      <div className={styles.term}>
        <p
          className={clsx(styles.termTitle, { [styles.clickable]: application?.canSwitchPaymentMethods })}
          onClick={() => {
            if (isApplicationLoading || !application?.canSwitchPaymentMethods) return;
            setShowPaymentOptions(!showPaymentOptions);
          }}
        >
          Payment per {getPaymentMethodLabel(paymentMethod)}
          {application?.canSwitchPaymentMethods && <SelectArrow />}
        </p>
        <p className={styles.termValue}>
          {application && !isApplicationLoading ? <>{paymentPerPaycheck}</> : <Loader color="#9d86f9" size={25} />}
        </p>
        {showPaymentOptions && paymentMethod === PaymentMethodEnum.Payroll && (
          <div className={styles.paymentOptions}>
            <p>
              We are able to offer lower rates because we take payment via direct deposit from payroll.
              <br />
              <br />
              If you switch to monthly payments from your checking account, your APR may increase.
            </p>
            <Button className={styles.firstButton} type={ButtonType.Danger} onClick={handleUpdatePaymentMethod}>
              Switch to Monthly Payments
            </Button>
            <Button onClick={() => setShowPaymentOptions(false)}>Cancel</Button>
          </div>
        )}
        {showPaymentOptions && paymentMethod === PaymentMethodEnum.ACH && (
          <div className={styles.paymentOptions}>
            <p>
              You’ve currently selected to pay each month via your checking account.
              <br />
              <br />
              Select to Pay per Paycheck may reduce your APR and increase savings.
            </p>
            <Button type={ButtonType.Primary} onClick={handleUpdatePaymentMethod}>
              Pay per Paycheck
            </Button>
            <Button
              className={styles.fullSize}
              type={ButtonType.Transparent}
              onClick={() => setShowPaymentOptions(false)}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const getPaymentMethodLabel = (paymentMethod?: PaymentMethodEnum) => {
  if (paymentMethod === PaymentMethodEnum.ACH) {
    return 'Month';
  }
  return 'Paycheck';
};

export default PaymentMethod;
