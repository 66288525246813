import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PageLoader from 'components/PageLoader';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { FlowComponentType } from 'routes/types';
import { getStudentLoanAssistanceData, getApplicationData as getApplicationDataThunk } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';

const StudentLoanLoaderWrapper = ({ children, flags }: Pick<FlowComponentType, 'children' | 'flags'>) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const studentLoanData = useSelector(getStudentLoanData);
  const { application, isLoading: isLoadingApplication } = useSelector(getApplicationData);
  const { applicationId, isLoading, fetched, refetch } = studentLoanData;
  const { enableStudentLoanAssistance } = flags;

  useEffect(() => {
    if (enableStudentLoanAssistance && (isLoading || !fetched) && applicationId) {
      dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId }));
    }

    // If there is a student loan application, fetch the application data
    if (applicationId && !application && !isLoadingApplication) {
      dispatchWithUnwrap(getApplicationDataThunk(applicationId));
    }
  }, [applicationId]);

  if (enableStudentLoanAssistance && (isLoading || !fetched) && !refetch) {
    return <PageLoader />;
  }
  return <>{children}</>;
};

export default StudentLoanLoaderWrapper;
