import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, RecommendationStrategy } from 'api/ApplicationDataApi';

export const createSetRecommendationStrategyThunk = (api: ApplicationDataApi) =>
  createAsyncThunk(
    'applicationData/setRecommendationStrategy',
    async (payload: { applicationId: string; strategy: RecommendationStrategy }, thunkApi) => {
      try {
        return await api.setRecommendationStrategy(payload.applicationId, payload.strategy);
      } catch (error: unknown) {
        return thunkApi.rejectWithValue(error);
      }
    },
  );
