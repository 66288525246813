import PreQualificationRestApi from 'api/PreQualificationApi';
import ArgyleRestApi from 'api/ArgyleApi';
import LoanOfferRestApi from 'api/LoanOfferApi';
import PreQualificationDeclineRestApi from 'api/PreQualificationDeclineApi';
import DocuSignEnvelopeRestApi from 'api/DocuSignEnvelopeApi';
import ApplicationDataRestApi from 'api/ApplicationDataApi';
import ApplicationDocumentsRestApi from 'api/DocumentsApi';
import EmployersApi from 'api/EmployersApi';
import PinwheelApiRest from 'api/PinwheelApi';
import AtomicApiRest from 'api/AtomicApi';
import UserDataApiRest from 'api/UserDataApi';
import PlaidRestApi from 'api/PlaidApi';
import EmailRestApi from 'api/EmailApi';
import PaymentAccountRestApi from 'api/PaymentAccountApi';
import MethodRestApi from 'api/MethodApi';
import CardRestApi from 'api/CardApi';
import ACHPaymentApi from 'api/PaymentApi';
import AuthRestApi from 'api/AuthApi';
import AlloyRestApi from 'api/AlloyApi';
import StudentLoanRestApi from 'api/StudentLoanApi';
import PaycheckSwitchRestApi from 'api/PaycheckSwitchApi';

import { getPlanneryApiUrl } from 'utils/getApiUrls';

import { createGetPayrollUserDataThunk } from './createGetPayrollUserDataThunk';
import { createLoanOfferThunk } from './createLoanOfferThunk';
import { createUpdateLoanOfferThunk } from './createUpdateLoanOfferThunk';
import { createLoanOfferXSellThunk } from './createLoanOfferXSellThunk';
import { createPreQualificationDeclineThunk } from './createPreQualificationDeclineThunk';
import { createDocuSignEnvelopeThunk } from './createDocuSignEnvelopeThunk';
import { createGetApplicationDataThunk } from './createGetApplicationDataThunk';
import { createBankAccountThunk } from './createBankAccountThunk';
import { createUploadDocumentsThunk } from './createUploadDocumentsThunk';
import { createUploadStudentAidDataThunk } from './createUploadStudentAidDataThunk';
import { createApplicationStatusThunk } from './createApplicationStatusThunk';
import { createPayDistributionConfigsEncryptThunk } from './createPayDistributionConfigsEncryptThunk';
import { createUserLocationThunk } from './createUserLocationThunk';
import createSearchEmployersThunk from './createSearchEmployersThunk';
import createCreatePinwheelLinkTokenThunk from './createCreatePinwheelLinkTokenThunk';
import createCreateAtomicAccessTokenThunk from './createCreateAtomicAccessTokenThunk';
import { createLinkTokenThunk } from './createLinkTokenThunk';
import { createSetBankAccountsThunk } from './createSetBankAccountsThunk';
import { createCreateApplicationThunk } from './createCreateApplicationThunk';
import { createGetCheckingAccounts } from './createGetCheckingAccountsThunk';
import { createFinishPlaidIncomeVerification } from './createFinishPlaidIncomeVerification';
import { createUpdateApplicationCheckingAccountThunk } from './createUpdateApplicationCheckingAccountThunk';
import { createSendOfferComparisonEmailThunk } from './createSendOfferComparisonEmail';
import { createFeedbackThunk } from './createFeedbackThunk';
import { createPaymentAccountCreationThunk } from './createPaymentAccountCreationThunk';
import { createGetPaymentAccountThunk } from './createGetPaymentAccountThunk';
import { createIdentityDataThunk } from './createIdentityDataThunk';
import { createUpdateEmploymentDataThunk } from './createUpdateEmploymentDataThunk';
import { createGetEmployerData } from './createGetEmployerDataThunk';
import { createSendDirectDepositEnrollmentFormEmail } from './createSendEnrollmentFormEmailThunk';
import { createSendStudentLoanEnrollmentFormEmail } from './createSendStudentLoanEnrollmentFormEmailThunk';
import { createUpdateSelectedTradelinesThunk } from './createUpdateSelectedTradelinesThunk';
import { createSetRecommendationStrategyThunk } from './createSetRecommendationStrategyThunk';
import { createUpdateAdditionalFundsThunk } from './createUpdateAdditionalFundsThunk';
import { createUpdatePaymentMethodThunk } from './createUpdatePaymentMethodThunk';
import { createElementTokenThunk, createAuthSessionThunk } from './createMethodThunk';
import { createLoanManagementThunk } from './createLoanManagementThunk';
import { createVerifyApplicationThunk } from './createVerifyApplicationThunk';
import { createCardApplyThunk, createCardApplyWithLoanOfferThunk } from './createCardApplyThunk';
import { createACHPaymentThunk } from './createACHPaymentThunk';
import { createGetCardDataForLoanApplication } from './createGetCardDataForLoanApplicationThunk';
import { createGetApplicationAprThunk } from './createGetApplicationAprThunk';
import {
  createAuthAuthSessionThunk,
  createAuthOtpThunk,
  createAuthSendSmsCodeForApplicationId,
  createAuthSendSmsCodeThunk,
  createAuthSendEmailCodeThunk,
} from './createAuthThunk';
import { createAlloyKycThunk } from './createAlloyThunk';
import { createStudentLoanAssistanceDataThunk } from './createStudentLoanAssistanceDataThunk';
import { setStudentLoanAssistanceScheduleThunk } from './setStudentLoanAssistanceSchedule';
import { createUpdateStudentLoanAssistanceDataThunk } from './createUpdateStudentLoanAssistanceDataThunk';
import { createGetPaycheckSwitchOfferThunk } from './createGetPaycheckSwitchOfferThunk';
import { createGetStudentLoanApplicationThunk } from './createGetStudentLoanApplicationThunk';
import { createUpdateStudentLoanApplicationThunk } from './createUpdateStudentLoanApplicationThunk';
import { createSetupStudentLoanPayment } from './createSetupStudentLoanPayment';
import { createGetStudentLoanApplicationPaymentStatusThunk } from './createGetStudentLoanPaymentStatusThunk';
import { createUploadPaystubsThunk } from './createUploadPaystubsThunk';
import { createSignEcfThunk } from './createSignEcfThunk';
import { createUploadEnrollmentFormThunk } from './createUploadEnrollmentFormThunk';
import { createPreviewEcfThunk } from './createPreviewEcfThunk';
import { createArgyleGetLinkDataThunk } from './createArgyleGetLinkDataThunk';
import { createSaveArgyleAccountThunk } from './createSaveArgyleAccountThunk';
import { createSetArgyleVerificationPendingThunk } from './createSetArgyleVerificationPendingThunk';

const apiUrl = getPlanneryApiUrl();

const applicationApi = new ApplicationDataRestApi(apiUrl);
export const getApplicationData = createGetApplicationDataThunk(applicationApi);
export const getApplicationApr = createGetApplicationAprThunk(applicationApi);
export const createApplication = createCreateApplicationThunk(applicationApi);
export const updateApplicationBankAccountData = createBankAccountThunk(applicationApi);
export const updateApplicationStatus = createApplicationStatusThunk(applicationApi);
export const updateApplicationCheckingAccount = createUpdateApplicationCheckingAccountThunk(applicationApi);
export const updateApplicationFeedbackData = createFeedbackThunk(applicationApi);
export const updateApplicationLoanManagementData = createLoanManagementThunk(applicationApi);
export const updateIdentityData = createIdentityDataThunk(applicationApi);
export const updateEmploymentData = createUpdateEmploymentDataThunk(applicationApi);
export const updateSelectedTradelines = createUpdateSelectedTradelinesThunk(applicationApi);
export const setRecommendationStrategy = createSetRecommendationStrategyThunk(applicationApi);
export const updateAdditionalFunds = createUpdateAdditionalFundsThunk(applicationApi);
export const updatePaymentMethod = createUpdatePaymentMethodThunk(applicationApi);

const alloyApi = new AlloyRestApi(apiUrl);
export const alloyKyc = createAlloyKycThunk(alloyApi);

const argyleApi = new ArgyleRestApi(apiUrl);
export const getPayDistributionConfigsEncrypt = createPayDistributionConfigsEncryptThunk(argyleApi);
export const getArgyleLinkData = createArgyleGetLinkDataThunk(argyleApi);
export const saveArgyleAccount = createSaveArgyleAccountThunk(argyleApi);
export const setArgyleVerificationPending = createSetArgyleVerificationPendingThunk(argyleApi);

const atomicApi = new AtomicApiRest(apiUrl);
export const createAtomicAccessToken = createCreateAtomicAccessTokenThunk(atomicApi);

const authApi = new AuthRestApi(apiUrl);
export const authSession = createAuthAuthSessionThunk(authApi);
export const authOtp = createAuthOtpThunk(authApi);
export const sendSmsCode = createAuthSendSmsCodeThunk(authApi);
export const sendEmailCode = createAuthSendEmailCodeThunk(authApi);

const cardApi = new CardRestApi(apiUrl);
export const applyForCard = createCardApplyThunk(cardApi);
export const applyForCardWithApplication = createCardApplyWithLoanOfferThunk(cardApi);
export const getCardDataForLoanApplication = createGetCardDataForLoanApplication(cardApi);

const docuSignEnvelopeApi = new DocuSignEnvelopeRestApi(apiUrl);
export const createDocuSignEnvelope = createDocuSignEnvelopeThunk(docuSignEnvelopeApi);

const documentsApi = new ApplicationDocumentsRestApi(apiUrl);
export const uploadDocuments = createUploadDocumentsThunk(documentsApi);

const emailApi = new EmailRestApi(apiUrl);
export const sendOfferComparisonEmail = createSendOfferComparisonEmailThunk(emailApi);
export const sendDirectDepositEnrollmentFormEmail = createSendDirectDepositEnrollmentFormEmail(emailApi);
export const sendStudentLoanEnrollmentForm = createSendStudentLoanEnrollmentFormEmail(emailApi);

const employersApi = new EmployersApi(apiUrl);
export const searchEmployers = createSearchEmployersThunk(employersApi);
export const getEmployerData = createGetEmployerData(employersApi);

const loanOfferApi = new LoanOfferRestApi(apiUrl);
export const getLoanOffer = createLoanOfferThunk(loanOfferApi);
export const updateLoanOffer = createUpdateLoanOfferThunk(loanOfferApi);
export const getLoanOfferXSell = createLoanOfferXSellThunk(loanOfferApi);
export const verifyApplication = createVerifyApplicationThunk(loanOfferApi);

const methodApi = new MethodRestApi(apiUrl);
export const elementTokenThunk = createElementTokenThunk(methodApi);
export const authSessionThunk = createAuthSessionThunk(methodApi);

const paycheckSwitchApi = new PaycheckSwitchRestApi(apiUrl);
export const getPaycheckSwitchOffer = createGetPaycheckSwitchOfferThunk(paycheckSwitchApi);

const paymentAccountApi = new PaymentAccountRestApi(apiUrl);
export const createPaymentAccount = createPaymentAccountCreationThunk(paymentAccountApi);
export const getPaymentAccount = createGetPaymentAccountThunk(paymentAccountApi);

const paymentApi = new ACHPaymentApi(apiUrl);
export const makeACHPayment = createACHPaymentThunk(paymentApi);
export const setupStudentLoanPayment = createSetupStudentLoanPayment(paymentApi);
export const getStudentLoanApplicationPaymentStatus = createGetStudentLoanApplicationPaymentStatusThunk(paymentApi);

const pinwheelApi = new PinwheelApiRest(apiUrl);
export const createPinwheelLinkToken = createCreatePinwheelLinkTokenThunk(pinwheelApi);

const plaidApi = new PlaidRestApi(apiUrl);
export const getLinkToken = createLinkTokenThunk(plaidApi);
export const setBankAccounts = createSetBankAccountsThunk(plaidApi);
export const getCheckingAccounts = createGetCheckingAccounts(plaidApi);
export const finishPlaidIncomeVerification = createFinishPlaidIncomeVerification(plaidApi);

const preQualificationApi = new PreQualificationRestApi(apiUrl);
export const getUserLocation = createUserLocationThunk(preQualificationApi);

const preQualificationDeclineApi = new PreQualificationDeclineRestApi(apiUrl);
export const sendNotification = createPreQualificationDeclineThunk(preQualificationDeclineApi);

const studentLoanApi = new StudentLoanRestApi(apiUrl);
export const uploadStudentAidData = createUploadStudentAidDataThunk(studentLoanApi);
export const uploadPaystubs = createUploadPaystubsThunk(studentLoanApi);
export const uploadEnrollmentForm = createUploadEnrollmentFormThunk(studentLoanApi);
export const getStudentLoanAssistanceData = createStudentLoanAssistanceDataThunk(studentLoanApi);
export const setStudentLoanAssistanceSchedule = setStudentLoanAssistanceScheduleThunk(studentLoanApi);
export const updateStudentLoanAssistanceData = createUpdateStudentLoanAssistanceDataThunk(studentLoanApi);
export const getStudentLoanApplication = createGetStudentLoanApplicationThunk(studentLoanApi);
export const updateStudentLoanApplication = createUpdateStudentLoanApplicationThunk(studentLoanApi);
export const sendSmsCodeForApplicationId = createAuthSendSmsCodeForApplicationId(authApi);
const userDataApi = new UserDataApiRest(apiUrl);
export const getUserPayrollData = createGetPayrollUserDataThunk(userDataApi);
export const signEcf = createSignEcfThunk(studentLoanApi);
export const previewEcf = createPreviewEcfThunk(studentLoanApi);
