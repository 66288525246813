import { LinkSearchKindType } from 'enums/LinkSearchKindType';

import Api, { FetchMethodType } from './Api';

export interface Employer {
  id: string | null;
  kind: LinkSearchKindType;
  name: string;
  logo_url: string;
}

interface EncryptedConfigResponse {
  encryptedConfig: string;
}

export interface DataForArgyleData {
  applicationId: string;
  refreshUserToken?: boolean;
}

export interface ArgyleLinkDataResponse {
  userToken: string;
  accounts?: string[];
}

export interface SaveArgyleAccountData {
  applicationId: string;
  accountId: string;
}

export interface SaveArgyleAccountResponse {
  success: boolean;
}

export interface ArgyleApi {
  getPayDistributionConfigsEncrypt(applicationId: string): Promise<EncryptedConfigResponse>;
  getArgyleLinkData(dataForUserToken: DataForArgyleData): Promise<ArgyleLinkDataResponse>;
  saveArgyleAccount(data: SaveArgyleAccountData): Promise<void>;
  setVerificationPending(applicationId: string): Promise<void>;
}

export default class ArgyleApiRest extends Api implements ArgyleApi {
  public async getPayDistributionConfigsEncrypt(applicationId: string) {
    const data = await this.fetch<EncryptedConfigResponse>(`/argyle/payDistributionConfigsEncrypt/${applicationId}`, {
      method: FetchMethodType.GET,
    });

    return data;
  }

  public async getArgyleLinkData({ applicationId, refreshUserToken }: DataForArgyleData) {
    const data = await this.fetch<ArgyleLinkDataResponse>(`/argyle/linkData`, {
      method: FetchMethodType.POST,
      body: {
        applicationId,
        refreshUserToken,
      },
    });

    return data;
  }

  public async saveArgyleAccount(data: SaveArgyleAccountData): Promise<void> {
    await this.fetch<void>(`/argyle/saveAccount`, {
      method: FetchMethodType.POST,
      body: data,
    });
  }

  public async setVerificationPending(applicationId: string): Promise<void> {
    await this.fetch<void>(`/argyle/setIncomeVerificationPending/${applicationId}`, {
      method: FetchMethodType.PUT,
    });
  }
}
