import React, { useEffect } from 'react';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { getIndefiniteArticle } from 'utils/getIndefiniteArticle';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { HardOfferData } from 'handlers/applicationData';
import { FlowComponentType } from 'routes/types';
import { ReactComponent as LockOpenIcon } from 'images/lock-open.svg';
import { ReactComponent as GiftIcon } from 'images/gift.svg';
import { ButtonType } from 'components/Button/Button';
import { YourSavingsResult } from 'enums/FlowNextResults';
import { ConversionType, trackConversion } from 'utils/analytics';
import LoanSummary from 'components/HardOffer/components/PayOffPlan/components/LoanSummary';

import useDiscounts from 'hooks/useDiscounts';

import styles from './YourSavings.module.scss';

const YourSavings = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const { application } = useSelector(getApplicationData);
  const { getHighlightedDiscountData } = useDiscounts();
  const badgeDiscountData = getHighlightedDiscountData();
  const hardOffer = (application?.hardOffer ?? {}) as Partial<HardOfferData>;
  const { applied: cardApplied } = useSelector(getCardData);
  const { offerSummary, additionalFundsTotal } = (hardOffer ?? {}) as HardOfferData;
  const { loanAmount, monthsSaved, moneySaved, monthlyPaymentSaved } = offerSummary ?? {};
  const { payOffTotal } = hardOffer;

  const totalDebt = payOffTotal?.amount ?? 0;

  const additionalFunds = additionalFundsTotal?.amount ?? 0;

  let displaySubtitle: string;

  if (additionalFunds === 0) {
    displaySubtitle = `You can use your Plannery loan to pay off ${formatMonetaryAmount(
      totalDebt,
    )} in high interest debt.`;
  } else if (totalDebt === 0) {
    displaySubtitle = `Plannery can help you borrow up to ${formatMonetaryAmount(
      additionalFunds,
    )} at below market rates.`;
  } else {
    displaySubtitle = `Plannery can help you pay ${formatMonetaryAmount(
      totalDebt,
    )} in high interest debt and borrow an additional ${formatMonetaryAmount(additionalFunds)}.`;
  }

  let title = 'We’ve Unlocked Savings for You!';

  if (cardApplied) {
    title = `Your Savings: ${formatMonetaryAmount(moneySaved)}`;
  }

  useEffect(() => {
    trackConversion(ConversionType.DebtConsolidationOfferAvailable);
  }, []);

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer className={styles.formContainer} icon={<LockOpenIcon />} title={title} subtitle={displaySubtitle}>
        <LoanSummary
          additionalFunds={additionalFunds}
          totalDebt={totalDebt}
          loanAmount={loanAmount}
          moneySaved={moneySaved}
          monthsSaved={monthsSaved}
          monthlyPaymentSaved={monthlyPaymentSaved}
        />

        {badgeDiscountData && (
          <div className={styles.loyaltyContainer}>
            <div className={styles.message}>
              Your offer includes {getIndefiniteArticle(badgeDiscountData.highlightedText)}{' '}
              <strong>{badgeDiscountData.highlightedText}</strong> {badgeDiscountData.badgeCustomDescription}
            </div>
            <GiftIcon className={styles.gift} />
          </div>
        )}

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              handleNext(totalDebt === 0 ? YourSavingsResult.ContinueNoSavings : YourSavingsResult.Continue);
            }}
          >
            {totalDebt === 0 ? 'View and Customize Offer' : 'See How It Works'}
          </Button>
          {cardApplied && (
            <Button
              type={ButtonType.Inverted}
              className={styles.buttonMarginTop}
              onClick={() => {
                handleNext(YourSavingsResult.BackToYourFinances);
              }}
            >
              Not Now
            </Button>
          )}
        </div>
      </FormContainer>
    </div>
  );
};

export default YourSavings;
