import { useSelector } from 'react-redux';

import { ReactComponent as ReferencesIcon } from 'images/step-icons/references-solid.svg';
import { ReactComponent as BriefCaseIcon } from 'images/step-icons/brief-case-solid.svg';
import { ReactComponent as LicenseIcon } from 'images/step-icons/license-solid.svg';
import { ReactComponent as PaystubsIcon } from 'images/step-icons/paystubs-solid.svg';
import { ReactComponent as GovernmentIcon } from 'images/step-icons/government-solid.svg';
import { ReactComponent as SignIcon } from 'images/step-icons/sign-solid.svg';

import { FlowComponentType } from 'routes/types';
import { StepsNavigation } from 'components/Steps/StepsNavigation/StepsNavigation';
import { useSteps, StepData, UseStepsReturnType } from 'components/Steps/stepUtils';
import FirstTimeFlow from 'components/Steps/StepsFlow/FirstTimeFlow';
import ReturningFlow from 'components/Steps/StepsFlow/ReturningFlow';
import { RoutePath } from 'enums/Routes';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { ApplyProgress, ApplyStep, KnownEmployerProfitStatus, defaultApplyProgress } from 'api/StudentLoanApi';
import { completeStudentLoanApplyStep, setCurrentStudentLoanApplyStep } from 'handlers/studentLoanApplicationData';

import EmploymentDetailsStep from './Steps/EmploymentDetails';
import PersonalDetailsStep from './Steps/PersonalDetails';
import ReferencesStep from './Steps/References';
import UploadIncomeVerificationStep from './Steps/UploadIncomeVerification';
import SignEnrollmentFormStep from './Steps/SignEnrollmentForm';
import ServiceAgreementStep from './Steps/ServiceAgreement';

export const applySteps: Readonly<StepData<ApplyStep>[]> = Object.freeze([
  {
    id: ApplyStep.PersonalDetails,
    name: 'Personal Details',
    component: PersonalDetailsStep,
    icon: LicenseIcon,
  },
  {
    id: ApplyStep.EmploymentDetails,
    name: 'Employment Details',
    component: EmploymentDetailsStep,
    icon: BriefCaseIcon,
  },
  {
    id: ApplyStep.References,
    name: 'References',
    component: ReferencesStep,
    icon: ReferencesIcon,
  },
  {
    id: ApplyStep.UploadIncomeVerification,
    name: 'Income Verification',
    component: UploadIncomeVerificationStep,
    icon: PaystubsIcon,
  },
  {
    id: ApplyStep.EnrollmentForm,
    name: 'Enrollment Form',
    component: SignEnrollmentFormStep,
    icon: GovernmentIcon,
  },
  {
    id: ApplyStep.SignServiceAgreement,
    name: 'Service Agreement',
    component: ServiceAgreementStep,
    icon: SignIcon,
  },
]);

const isRelevantStep = (step: ApplyStep, forProfit: boolean): boolean => {
  if (forProfit) {
    return step !== ApplyStep.EnrollmentForm;
  }
  return true;
};

export const useApplySteps = (): UseStepsReturnType<ApplyStep, ApplyProgress> => {
  const {
    currentApplyStep,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanApplication);
  const { knownEmployerProfitStatus } = useSelector(getStudentLoanData);

  const forProfit = knownEmployerProfitStatus !== KnownEmployerProfitStatus.NonProfit;

  const steps = applySteps.filter((step) => isRelevantStep(step.id, forProfit));

  return useSteps(
    currentApplyStep ?? applySteps[0].id,
    setCurrentStudentLoanApplyStep,
    completeStudentLoanApplyStep,
    steps,
    applyProgress ?? defaultApplyProgress,
    RoutePath.StudentLoanApplyStepsCompleted,
  );
};

export const ApplyNavigation = (args: FlowComponentType) =>
  StepsNavigation<ApplyStep, ApplyProgress>(args, useApplySteps);
export const ApplyFirstTimeFlow = () => FirstTimeFlow<ApplyStep, ApplyProgress>(useApplySteps);
export const ApplyReturningFlow = () =>
  ReturningFlow<ApplyStep, ApplyProgress>(useApplySteps, RoutePath.StudentLoanApplySteps);
