import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonType } from 'components/Button/Button';
import { StepComponent } from 'components/Steps/stepUtils';
import FormContainer from 'components/LoanForm/FormContainer';
import PartnerBanner from 'components/Common/PartnerBanner';
import ArgyleWidget from 'components/SetupDeduction/AutomaticDeduction/payrollProvider/argyle/ArgyleWidget';

import { StepsResult } from 'enums/FlowNextResults';

import { ReactComponent as Transfer } from 'images/transfer.svg';
import { ReactComponent as TaskDone } from 'images/task-done.svg';
import { ReactComponent as Secure } from 'images/lock.svg';

import { getApplicationData } from 'selectors/getApplicationData';

import { setArgyleVerificationPending } from 'thunks';

import styles from './Argyle.module.scss';

const descriptionItems = [
  {
    description: 'Connects to your HR system, so you don’t have to upload documents.',
    icon: Transfer,
  },
  {
    description: 'Instantly verifies your employment income, giving you the fastest path to accessing your funds.',
    icon: TaskDone,
  },
  {
    description: 'Uses bank level security protocols.',
    icon: Secure,
  },
];

const ArgyleIncomeVerification = ({ handleNext }: StepComponent): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { application } = useSelector(getApplicationData);
  const dispatch = useDispatch();

  return (
    <>
      <ArgyleWidget
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={async () => {
          await dispatch(setArgyleVerificationPending(application!.id));
          handleNext(StepsResult.Completed);
          setOpen(false);
        }}
        application={application!}
      />
      <FormContainer
        title="Verify Your Income"
        subtitle="Plannery partners with Argyle to verify your income and employment."
      >
        <div className={styles.container}>
          <PartnerBanner name="Argyle" logo={<img src="/argyle.png" alt="Argyle" />} />
          <div className={styles.descriptionContainer}>
            <div className={styles.descriptionItems}>
              {descriptionItems.map((item, index) => (
                <div className={styles.item} key={index}>
                  <item.icon className={styles.icon} />
                  <div className={styles.itemText}>{item.description}</div>
                </div>
              ))}
            </div>
          </div>
          <Button className={styles.button} onClick={() => setOpen(true)}>
            Next
          </Button>
          <Button
            className={styles.secondaryButton}
            onClick={() => handleNext(StepsResult.DoThisLater)}
            type={ButtonType.Secondary}
          >
            Do this later
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default ArgyleIncomeVerification;
