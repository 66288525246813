import React from 'react';
import Button, { ButtonType } from 'components/Button/Button';
import { ReactComponent as CopyIcon } from 'images/copy-icon.svg';
import { copyToClipboard } from 'utils/copyToClipboard';
import { FlowComponentType } from 'routes/types';
import useCurrentFlow from 'hooks/useCurrentFlow';
import useHardOfferData from 'hooks/useHardOfferData';

import styles from './NextSteps.module.scss';

const NextSteps = ({ handleNext }: FlowComponentType) => {
  const { isFinancialCheckupFlow, isDebtConsolidationFlow } = useCurrentFlow();

  const { amountWeWillSendYouToPay } = useHardOfferData();

  const supportEmail = 'support@planneryapp.com';

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Next steps</h1>
      <div>
        {amountWeWillSendYouToPay > 0 && (
          <div className={styles.descriptionContainer}>
            <p className={styles.header}>Pay off your debt right away</p>
            <p className={styles.subtitle}>
              Once the fund hits your account, make sure you pay off your expensive debt immediately
            </p>
          </div>
        )}
        <div className={styles.descriptionContainer}>
          <p className={styles.header}>Manage your loan</p>
          <p className={styles.subtitle}>We’ve sent an email with a link to access our loan management portal.</p>
          <br />
          <p className={styles.subtitle}>
            Make sure you receive our emails. Add{' '}
            <span className={styles.clip} onClick={() => copyToClipboard(supportEmail)}>
              {supportEmail} <CopyIcon className={styles.icon} />
            </span>{' '}
            to your safe sender list.
          </p>
        </div>
        {isDebtConsolidationFlow && (
          <div className={styles.descriptionContainer}>
            <p className={styles.header}>What's Next</p>
            <div className={styles.subtitle}>See how else Plannery can help you save money. </div>
            <Button className={styles.nextButton} onClick={handleNext}>
              See Your Finances
            </Button>
          </div>
        )}
      </div>
      {isFinancialCheckupFlow && (
        <Button type={ButtonType.Primary} onClick={handleNext} className={styles.backButton}>
          Back to Your Finances
        </Button>
      )}
    </div>
  );
};

export default NextSteps;
