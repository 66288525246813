import { StudentLoanAssistancePaymentStatus } from 'handlers/applicationData';

import Api, { FetchMethodType } from './Api';

export enum ApplyStep {
  PersonalDetails = 'personalDetails',
  EmploymentDetails = 'employmentDetails',
  References = 'references',
  UploadIncomeVerification = 'incomeVerification',
  UploadLoanInformation = 'loanInformation',
  EnrollmentForm = 'enrollmentForm',
  SignServiceAgreement = 'serviceAgreement',
}

export type ApplyProgress = {
  [ApplyStep.PersonalDetails]: boolean;
  [ApplyStep.EmploymentDetails]: boolean;
  [ApplyStep.References]: boolean;
  [ApplyStep.UploadIncomeVerification]: boolean;
  [ApplyStep.UploadLoanInformation]: boolean;
  [ApplyStep.EnrollmentForm]: boolean;
  [ApplyStep.SignServiceAgreement]: boolean;
};

export const defaultApplyProgress: Readonly<ApplyProgress> = Object.freeze({
  [ApplyStep.PersonalDetails]: false,
  [ApplyStep.EmploymentDetails]: false,
  [ApplyStep.References]: false,
  [ApplyStep.UploadIncomeVerification]: false,
  [ApplyStep.UploadLoanInformation]: false,
  [ApplyStep.EnrollmentForm]: false,
  [ApplyStep.SignServiceAgreement]: false,
});

export enum KnownEmployerProfitStatus {
  ForProfit = 'For Profit',
  NonProfit = 'Non Profit',
  Unknown = 'Unknown',
}

export interface StudentLoanAssistanceData {
  eligible: boolean;
  paymentStartDate?: string;
  nonProfitStartDate: string | null;
  knownEmployerProfitStatus: KnownEmployerProfitStatus;
  allLoansMohela?: boolean;
  hasMonthlyPayments?: boolean;
  maxTerm?: number;
  totalBalance?: number;
  monthSavings?: number;
  currentPath?: PayoffData;
  recommendedPath?: PayoffData;

  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export enum PlanShortName {
  Standard = 'Standard',
  Extended = 'Extended',
  Graduated = 'Graduated',
  ExtendedGraduated = 'Extended Graduated',
  Save = 'SAVE',
  Paye = 'PAYE',
  Icr = 'ICR',
  Ibr = 'IBR',
}

export const CURRENT_PLAN = 'Current Plan';
export const PSLF = 'PSLF';

export type PlanName = PlanShortName | typeof PSLF | typeof CURRENT_PLAN;

export interface PayoffData {
  plans: PlanName[];
  monthsToDebtFree: number;
  monthlyPayment: number;
  totalPayment: number;
  payments: number[];
}

export interface StudentLoanCalculationParameters {
  forProfit?: boolean | null;
  nonProfitStartDate?: string;
  householdSize?: number;
  undergraduateLoansPercentage?: number;
  enrolledIncomeDrivenRepayment?: boolean;
}

export interface StudentLoanScheduleData {
  eventUri?: string;
  eventStartTime?: string;
  eventHost?: string;
  eventHostEmail?: string;
  eventName?: string;
  studentLoanData?: StudentLoanAssistanceData;
}

export interface PersonalReference {
  firstName?: string;
  lastName?: string;
  relationship?: Relationship;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phoneNumber?: string;
}

export enum Relationship {
  Mother = 'mother',
  Father = 'father',
  Child = 'child',
  Friend = 'friend',
  Sister = 'sister',
  Brother = 'brother',
  OtherFamilyMember = 'other family member',
}

export enum IncomeTaxFilingStatus {
  Single = 'Single',
  MarriedFilingJointly = 'Married filing jointly',
  MarriedFilingSeparately = 'Married filing separately',
  HeadOfHousehold = 'Head of household',
}

export enum MaritalStatus {
  Single = 'Single',
  Married = 'Married',
}

export interface ApplicationData {
  employerName?: string;
  hrContactName?: string;
  hrContactEmail?: string;
  ein?: string;
  employerAddress?: string;
  employerCity?: string;
  employerState?: string;
  employerZip?: string;
  employerPhone?: string;
  familySize?: number;
  adjustedGrossIncome?: number;
  incomeTaxFilingStatus?: IncomeTaxFilingStatus;
  maritalStatus?: MaritalStatus;
  driversLicenseState?: string;
  driversLicenseNumber?: string;
  reference1?: PersonalReference;
  reference2?: PersonalReference;
  applyProgress?: ApplyProgress | null;
  paymentStatus?: StudentLoanAssistancePaymentStatus;
}

export interface UploadResponse {
  applyProgress?: ApplyProgress | null;
}

export interface StudentAidDataUploadResponse extends UploadResponse {
  estimateChanged: boolean;
}

export interface StudentLoanApi {
  getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData>;
  setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<StudentLoanScheduleData>;
  updateStudentLoanAssistantData(
    applicationId: string,
    data: StudentLoanCalculationParameters,
  ): Promise<StudentLoanAssistanceData>;
  getApplication(applicationId: string): Promise<ApplicationData>;
  updateApplication(applicationId: string, data: ApplicationData): Promise<ApplicationData>;
  uploadStudentAidData(applicationId: string, file: File): Promise<StudentAidDataUploadResponse>;
  uploadPaystubs(applicationId: string, files: File[]): Promise<UploadResponse>;
  uploadEnrollmentForm(applicationId: string, file: File): Promise<UploadResponse>;
  previewEnrollmentForm(applicationId: string): Promise<string>;
  signEnrollmentForm(applicationId: string, signatureDataUrlPng: string): Promise<string>;
}

export default class StudentLoanRestApi extends Api implements StudentLoanApi {
  async getStudentLoanAssistanceData(applicationId: string): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`);
  }

  async setStudentLoanAssistanceSchedule(applicationId: string, eventUri?: string): Promise<StudentLoanScheduleData> {
    return this.fetch(`/studentLoan/schedule/${applicationId}/`, {
      method: FetchMethodType.POST,
      body: { eventUri },
    });
  }

  async updateStudentLoanAssistantData(
    applicationId: string,
    data: StudentLoanCalculationParameters,
  ): Promise<StudentLoanAssistanceData> {
    return this.fetch<StudentLoanAssistanceData>(`/studentLoan/assistanceData/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: data,
    });
  }

  async getApplication(applicationId: string): Promise<ApplicationData> {
    return this.fetch(`/studentLoan/application/${applicationId}`, {
      method: FetchMethodType.GET,
    });
  }

  async updateApplication(applicationId: string, data: ApplicationData): Promise<ApplicationData> {
    return this.fetch<ApplicationData>(`/studentLoan/application/${applicationId}`, {
      method: FetchMethodType.PUT,
      body: data,
    });
  }

  async uploadStudentAidData(applicationId: string, file: File): Promise<StudentAidDataUploadResponse> {
    const formData = new FormData();

    formData.append('files', file);
    formData.append('applicationId', applicationId);

    const result = await this.fetch<StudentAidDataUploadResponse>(
      `/studentLoan/application/${applicationId}/studentAidData`,
      {
        method: FetchMethodType.POST,
        body: formData,
        resetDefaultHeaders: true,
      },
    );

    return result;
  }

  async uploadPaystubs(applicationId: string, files: File[]): Promise<UploadResponse> {
    const formData = new FormData();

    files.map((file) => formData.append('files', file));

    formData.append('applicationId', applicationId);

    const result = await this.fetch<UploadResponse>(`/studentLoan/application/${applicationId}/paystubs`, {
      method: FetchMethodType.POST,
      body: formData,
      resetDefaultHeaders: true,
    });

    return result;
  }

  async uploadEnrollmentForm(applicationId: string, file: File): Promise<UploadResponse> {
    const formData = new FormData();

    formData.append('files', file);
    formData.append('applicationId', applicationId);

    const result = await this.fetch<UploadResponse>(`/studentLoan/application/${applicationId}/enrollmentForm`, {
      method: FetchMethodType.POST,
      body: formData,
      resetDefaultHeaders: true,
    });

    return result;
  }

  async previewEnrollmentForm(applicationId: string): Promise<string> {
    const result = await this.fetch<string>(`/studentLoan/application/${applicationId}/previewEnrollmentForm`, {
      method: FetchMethodType.POST,
      body: {},
    });
    return result;
  }

  async signEnrollmentForm(applicationId: string, signatureDataUrlPng: string): Promise<string> {
    const result = await this.fetch<string>(`/studentLoan/application/${applicationId}/signEnrollmentForm`, {
      method: FetchMethodType.POST,
      body: { signatureDataUrlPng },
    });
    return result;
  }
}
