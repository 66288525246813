export const getIndefiniteArticle = (word: string): string => {
  const vowelSoundPrefixes = ['a', 'e', 'i', 'o', 'u'];
  const silentConsonantCases = ['honest', 'hour', 'heir'];

  const lowerCaseWord = word.toLowerCase();

  if (silentConsonantCases.includes(lowerCaseWord) || vowelSoundPrefixes.includes(lowerCaseWord[0])) {
    return 'an';
  }

  return 'a';
};
