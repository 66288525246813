import React, { useEffect, useState } from 'react';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import { ConversionType, trackConversion } from 'utils/analytics';
import { StudentLoanApplyOptionsResult } from 'enums/StudentLoanForgivenessFlowResults';
import CalendlyModal from 'components/StudentLoanForgiveness/Modal/CalendlyModal';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import { useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getStudentLoanApplication as getStudentLoanApplicationThunk } from 'thunks';
import { ReactComponent as Contract } from 'images/contract.svg';

import ToggleButtonGroup, { ToggleButton } from './components/ToggleButtonGroup';

import styles from './ApplyOptions.module.scss';

enum Result {
  ApplyWithUs = 'Apply with Us',
  ApplyOnYourOwn = 'Apply on Your Own',
  TalkToAnAdvisor = 'Talk to an Advisor',
}

const ApplyOptions = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { currentPath: currentPathData, recommendedPath: recommendedPathData } = useSelector(getStudentLoanData);

  const savings =
    currentPathData?.totalPayment !== undefined && recommendedPathData?.totalPayment !== undefined
      ? currentPathData?.totalPayment - recommendedPathData?.totalPayment
      : null;

  const recommendAdvisor = (savings ?? 0) > 50_000 && recommendedPathData?.plans.includes('PSLF');

  const [isScheduleOpen, setScheduleIsOpen] = useState(false);
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [selected, setSelected] = useState(recommendAdvisor ? Result.ApplyWithUs : Result.ApplyOnYourOwn);

  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useSelector(getStudentLoanData);
  const { fetched, isLoading } = useSelector(getStudentLoanApplication);
  const [clickedNext, setClickedNext] = useState(false);

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    applicationId &&
      (async () => {
        await dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId }));
      })();
  }, []);

  useEffect(() => {
    if (fetched && clickedNext) {
      handleNext(StudentLoanApplyOptionsResult.ApplyWithUs);
    }
  }, [isLoading, clickedNext]);

  const onNext = () => {
    analytics.track('Student Loan Apply Option Selected', { selected });
    switch (selected) {
      case Result.TalkToAnAdvisor:
        setAreYouSureModalOpen(false);
        setScheduleIsOpen(true);
        break;
      case Result.ApplyOnYourOwn:
        handleNext(StudentLoanApplyOptionsResult.ApplyOnYourOwn);
        break;
      case Result.ApplyWithUs:
        trackConversion(ConversionType.StudentLoanApplyWithUs);
        setClickedNext(true);
        break;
      default:
    }
  };

  const onSchedule = () => {
    trackConversion(ConversionType.BookedStudentLoanMeeting);
    setScheduleIsOpen(false);
    handleNext(StudentLoanApplyOptionsResult.Schedule);
  };

  const handleNotNow = () => {
    analytics.track('Schedule Consultation Not Now Pressed');
    handleNext(StudentLoanApplyOptionsResult.NotNow);
  };

  const advisorOption: ToggleButton<string> = {
    value: Result.ApplyWithUs,
    title: 'Enroll with an Advisor',
    chip: recommendAdvisor ? 'Recommended' : undefined,
    subtitle: 'Stress free – our student loan advisor partners do the work for you.',
    children: (
      <ul className={styles.descriptionList}>
        <li>Schedule a consultation.</li>
        <li>An expert will review your information, apply and appeal on your behalf.</li>
        <li>Guaranteed enrollment or your money back.</li>
      </ul>
    ),
  };

  const selfServiceOption: ToggleButton<string> = {
    value: Result.ApplyOnYourOwn,
    title: 'Enroll on StudentAid.gov',
    subtitle: 'We’ll point you in the right direction.',
    children: (
      <ul className={styles.descriptionList}>
        <li>We’ll give you more information about applying at no cost.</li>
      </ul>
    ),
  };

  const options = recommendAdvisor ? [advisorOption, selfServiceOption] : [selfServiceOption, advisorOption];

  return (
    <>
      <CalendlyModal open={isScheduleOpen} onClose={() => setScheduleIsOpen(false)} onSchedule={onSchedule} />
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={() => setAreYouSureModalOpen(false)}
          onNotNow={handleNotNow}
          nextLabel="Continue Applying"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Let's Enroll!"
        subtitle={
          recommendAdvisor
            ? 'Since your estimated forgiveness amount is high, it may be worth talking to a student loan advisor.'
            : 'Start by enrolling on studentaid.gov. If you need assistance, we can connect you with student loan advisors.'
        }
        icon={<Contract />}
      >
        <ToggleButtonGroup
          defaultValue={selected}
          onChange={(value) => setSelected(value as Result)}
          options={options}
        />

        <div className={styles.buttonContainer}>
          <Button onClick={onNext} isLoading={clickedNext && isLoading}>
            Next
          </Button>

          <Button
            type={ButtonType.Transparent}
            onClick={() => setAreYouSureModalOpen(true)}
            isLoading={clickedNext && isLoading}
          >
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export default ApplyOptions;
