import { createAsyncThunk } from '@reduxjs/toolkit';
import { PlaidApi } from 'api/PlaidApi';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';

export const createLinkTokenThunk = (api: PlaidApi) =>
  createAsyncThunk('plaid/getLinkToken', async (applicationId: string) => {
    const linkToken = await api.getLinkToken(applicationId);
    localStorage.setItem(LocalStorageKeyName.LinkToken, JSON.stringify(linkToken));
    return linkToken;
  });

export default createLinkTokenThunk;
