import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi, DataForUpdatingPaymentMethod } from 'api/ApplicationDataApi';

export const createUpdatePaymentMethodThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/updatePaymentMethod', async (data: DataForUpdatingPaymentMethod, thunkApi) => {
    try {
      return await api.updatePaymentMethod(data.applicationId, data.paymentMethod);
    } catch (error: unknown) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
