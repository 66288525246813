import { createAsyncThunk } from '@reduxjs/toolkit';
import { ArgyleApi, DataForArgyleData } from 'api/ArgyleApi';

import { ErrorResponse } from './ErrorResponse';

export const createArgyleGetLinkDataThunk = (api: ArgyleApi) =>
  createAsyncThunk('argyle/getLinkData', async (data: DataForArgyleData, thunkApi) => {
    try {
      const response = await api.getArgyleLinkData(data);
      return response;
    } catch (error: unknown) {
      return thunkApi.rejectWithValue((error as ErrorResponse).response.data);
    }
  });

export default createArgyleGetLinkDataThunk;
