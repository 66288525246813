import React from 'react';
import clsx from 'clsx';

import styles from './CardSequence.module.scss';

export interface Step {
  title: string | JSX.Element;
  body?: string | JSX.Element;
  icon?: JSX.Element;
  center?: boolean;
}

interface Props {
  steps: Step[];
}

const CardSequence = ({ steps }: Props) => (
  <div className={styles.cardSequence}>
    {steps.map((step, index) => (
      <div className={clsx(styles.step, step.center && styles.center)} key={index}>
        {step.icon && <div className={styles.icon}>{step.icon}</div>}
        <div className={styles.stepContent}>
          <div className={styles.title}>{step.title}</div>
          {step.body && <div className={styles.body}>{step.body}</div>}
        </div>
      </div>
    ))}
  </div>
);

export default CardSequence;
