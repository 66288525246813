import React from 'react';
import { useSelector } from 'react-redux';
import { Chart, AxisOptions } from 'react-charts';
import clsx from 'clsx';

import { addMonths } from 'date-fns';
import Expander from 'components/Expander/Expander';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import Loader from 'components/Loader';

import styles from './SavingsTimeChart.module.scss';

interface Props {
  advisorCost?: number[];
  children?: React.ReactNode;
}

const SavingsTimeChart = ({ advisorCost, children }: Props) => {
  const { currentPath, recommendedPath, isLoading: isLoadingStudentLoanAssistanceData } = useSelector(
    getStudentLoanData,
  );

  const currentPathCost = currentPath?.totalPayment ?? 0;
  const recommendedPathCost =
    (recommendedPath?.totalPayment ?? 0) + (advisorCost ?? []).reduce((total, value) => total + value, 0);
  const savings = currentPathCost - recommendedPathCost;

  const startDate = new Date();

  const chartData = React.useMemo(
    () => getChartData(advisorCost, startDate, currentPath?.payments, recommendedPath?.payments),
    [advisorCost, isLoadingStudentLoanAssistanceData],
  );
  const primaryAxis = React.useMemo(
    (): AxisOptions<DataPoint> => ({
      getValue: (datum) => datum.date,
      hardMin: startDate,
      min: startDate,
      padBandRange: false,
      formatters: {
        cursor: (value: Date) =>
          value &&
          value.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }),
        tooltip: (value: Date) =>
          value &&
          value.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }),
      },
    }),
    [],
  );
  const secondaryAxes = React.useMemo(
    (): AxisOptions<DataPoint>[] => [
      {
        getValue: (datum) => datum.amount,
        formatters: {
          scale: (value: number) => formatMonetaryAmount(value),
        },
      },
    ],
    [],
  );

  return (
    <>
      {isLoadingStudentLoanAssistanceData && (
        <div className={styles.loader}>
          <Loader color="#9d86f9" size={90} />
        </div>
      )}
      {savings > 0 && !isLoadingStudentLoanAssistanceData && (
        <Expander
          label={<div className={styles.row}>{children}</div>}
          content={
            <div className={styles.savingsDetail}>
              <div className={styles.legend}>
                <div className={clsx(styles.item, styles.highlighted)}>
                  <div className={styles.bullet} />
                  <div className={styles.content}>
                    <div className={styles.title}>Updated Path including fees</div>
                    <div className={styles.value}>({formatMonetaryAmount(recommendedPathCost)} Total Payment)</div>
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.bullet} />
                  <div className={styles.content}>
                    <div className={styles.title}>Original Path</div>
                    <div className={styles.value}>({formatMonetaryAmount(currentPathCost)} Total Payment)</div>
                  </div>
                </div>
              </div>
              <div className={styles.chartWrapper}>
                <Chart
                  options={{
                    data: chartData,
                    primaryAxis,
                    secondaryAxes,
                    defaultColors: ['#795AF7' /* purple500 */, '#C5CBD4' /* black20 */],
                  }}
                />
              </div>
            </div>
          }
          labelClassname={styles.expanderTitle}
          container={styles.expanderContainer}
          openClassname={styles.expanderOpen}
          translateArrow={false}
        />
      )}
    </>
  );
};

interface DataPoint {
  date: Date;
  amount: number;
}

const getChartData = (
  advisorCost: number[] | undefined,
  startDate: Date,
  currentPathPayments?: number[],
  recommendedPathPayments?: number[],
): { label: string; data: DataPoint[] }[] => {
  const currentPayments = [...(currentPathPayments ?? [])];
  const recommendedPayments = [...(recommendedPathPayments ?? [])];

  (advisorCost ?? []).forEach((amount, index) => {
    if (index === recommendedPayments.length) {
      recommendedPayments.push(0);
    }
    recommendedPayments[index] += amount;
  });

  while (recommendedPayments.length < currentPayments.length) {
    recommendedPayments.push(0);
  }

  return [
    {
      label: 'Recommended Path',
      data: convertToCumulativeSeries(startDate, recommendedPayments),
    },
    {
      label: 'Current Path',
      data: convertToCumulativeSeries(startDate, currentPayments),
    },
  ];
};

const convertToCumulativeSeries = (startDate: Date, payments: number[]): DataPoint[] => {
  let totalPayment = 0;
  return payments.map((payment, index) => {
    totalPayment += payment;
    return { date: addMonths(startDate, index), amount: totalPayment };
  });
};

export default SavingsTimeChart;
