import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import Loader from 'components/Loader';
import Expander from 'components/Expander';
import useDiscounts from 'hooks/useDiscounts';
import { formatNumber } from 'utils/formatNumber';
import { PartnerType } from 'enums/PartnerName';
import { getApplicationData } from 'selectors/getApplicationData';
import { HardOfferData } from 'handlers/applicationData';
import { getApplicationApr } from 'thunks';

import styles from './APRBreakdown.module.scss';

const APRBreakdown = (): JSX.Element => {
  const dispatch = useDispatch();
  const { isLoading: isApplicationLoading } = useSelector(getApplicationData);

  const { applicableDiscounts } = useDiscounts();
  const { apr, isAprLoading, application } = useSelector(getApplicationData);
  const hardOffer = (application?.hardOffer ?? {}) as Partial<HardOfferData>;
  const { discountSummary } = (hardOffer ?? {}) as HardOfferData;
  const { baseApr: baseAPR } = discountSummary ?? {};

  useEffect(() => {
    if (!apr && !isAprLoading && application) dispatch(getApplicationApr(application.id));
  }, [application]);

  if (!apr || isApplicationLoading || isAprLoading) {
    return (
      <div className={clsx(styles.breakdownMainItem, styles.breakdownMainItemSpacing)}>
        <p className={styles.breakdownTitle}>APR</p>
        <p className={clsx(styles.breakdownValue, styles.bold)}>
          <Loader color="#9d86f9" size={25} />
        </p>
      </div>
    );
  }

  if (!baseAPR || applicableDiscounts.length === 0) {
    return (
      <div className={clsx(styles.breakdownMainItem, styles.breakdownMainItemSpacing)}>
        <p className={styles.breakdownTitle}>APR</p>
        <p className={clsx(styles.breakdownValue, styles.bold)}>{formatNumber(apr, true)}%</p>
      </div>
    );
  }

  return (
    <Expander
      label={
        <div className={styles.breakdownMainItem}>
          <p className={styles.breakdownTitle}>Your Discounted APR</p>
          <div className={styles.APRComparisonContainer}>
            <p className={clsx(styles.breakdownValue, styles.textStrikeThrough)}>{baseAPR.toFixed(2)}%</p>
            <p className={clsx(styles.breakdownValue, styles.bold)}>{formatNumber(apr, true)}%</p>
          </div>
        </div>
      }
      content={
        <div className={styles.breakdownContentSection}>
          <div className={styles.breakdownItem}>
            <p className={styles.breakdownTitle}>Plannery APR</p>
            <p className={styles.breakdownValue}>{baseAPR.toFixed(2)}%</p>
          </div>
          {applicableDiscounts.map((discount) => (
            <div key={discount.discountName}>
              <div className={styles.breakdownItem}>
                <p className={styles.breakdownTitle}>
                  {discount.isTenureDiscount ? 'Tenure' : discount.discountName}{' '}
                  {discount.partnerType === PartnerType.Association && 'Member'} Discount
                </p>
                <p className={styles.breakdownValue}>-{discount.aprDifference.toFixed(2)}%</p>
              </div>
              {discount.isTenureDiscount && (
                <p className={styles.discountDescription}>
                  You receive a lower rate because you’ve been employed at {discount.discountName} for over{' '}
                  {discount.tenureYears} years.
                </p>
              )}
            </div>
          ))}
        </div>
      }
      labelClassname={styles.expanderLabel}
      openClassname={styles.expanderOpen}
    />
  );
};

export default APRBreakdown;
